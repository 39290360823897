.tableHeader {
    background-color: #eeeeee; /* Replace this with theme.palette.grey[200] */
    border-bottom: 2px solid #000;
    height: 20px;
    max-height: 30px;
}

.tableRow:hover {
    cursor: pointer;
}

.tableRow:nth-of-type(even) {
    background-color: #f5f5f5; /* Replace this with theme.palette.action.hover */
}
