.card-box-shadow {
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px !important;
}


.custom-options-container {
    border: 1px solid #515A5A;
    border-radius: 4px;
}

/*!* Customize the scrollbar width *!*/
/*::-webkit-scrollbar {*/
/*    width: 4px; !* Width of the vertical scrollbar *!*/
/*    height: 4px; !* Height of the horizontal scrollbar *!*/
/*}*/

/*!* Customize the scrollbar track *!*/
/*::-webkit-scrollbar-track {*/
/*    background: #f1f1f1; !* Background color of the scrollbar track *!*/
/*}*/

/*!* Customize the scrollbar handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    background: #888; !* Color of the scrollbar handle *!*/
/*    border-radius: 5px; !* Rounded corners *!*/
/*}*/

/*!* Hover effect for the scrollbar handle *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*    background: #555; !* Color of the scrollbar handle on hover *!*/
/*}*/